<template>
  <div class="device-index">
    <div class="device-list">
      <el-form label-width="auto" :inline="true" size="mini">


        <el-col :span="6">
          <el-form-item label="条件搜索:">
            <el-input placeholder="企业名称" class="device-input-filter clear-right-padding"
                      clearable
                      v-model="pagination.name"
                      @change="loadCompany"
                      prefix-icon="el-icon-search"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="16" style="text-align: right">
          <span>企业唯有分配角色才可扫码登录</span>
        </el-col>
      </el-form>
      <el-table
        :data="companies">
        <empty slot="empty"></empty>
        <el-table-column label="企业编号" align="center" prop="corpid" min-width="100"></el-table-column>
        <el-table-column label="企业名称" align="center" prop="name" min-width="120"></el-table-column>

        <el-table-column label="操作" align="center" min-width="180">
          <template slot-scope="scope">
            <el-button type="text" @click="handleAllocateRole(scope.row)">分配角色</el-button>
            <el-button type="text" @click="handleAllocateDevice(scope.row)">分配设备</el-button>
            <el-button type="text" @click="handleBindDevice(scope.row)">已绑设备</el-button>
          </template>
        </el-table-column>

      </el-table>
      <el-pagination
        @current-change="handleCurrentChange"
        class="pagination"
        :current-page="pagination.curPage"
        :page-size="pagination.limit"
        layout="total, prev, pager, next, jumper"
        :total="pagination.total">
      </el-pagination>

    </div>
    <c-dialog :visible.sync="bindVisible" :width="'42rem'" :height="'36rem'">
      <div slot="title" class="input-title">
        <p class="input-title-text">绑定设备</p>
        <div class="input-title-container">
          <el-button size="mini" type="primary" plain @click="handleBindDepartment">关联设备</el-button>
        </div>

        <div class="input-title-filter">
          <p>过滤条件:</p>
          <el-input v-model="bind.name" placeholder="请输入设备名称" @change="handleInputChange" size="mini"/>
        </div>

      </div>

      <devices ref="devices" :can="1" ></devices>
    </c-dialog>

    <c-dialog class="dialog" :visible.sync="deviceVisible" :width="'50rem'" :height="'37.5rem'"
              :title="'已绑设备'">
      <bind-device ref="payDevice" ></bind-device>
    </c-dialog>

    <el-dialog title="绑定角色" width="24%" :visible.sync="showRole">
      <el-form label-width="80px">
        <el-form-item label="选取角色">
          <el-select v-model="role.uuid">
            <el-option v-for="role in roles" :key="role.uuid" :value="role.uuid" :label="role.name"/>
          </el-select>
        </el-form-item>
      </el-form>

      <div slot="footer" style="text-align: center;">
        <el-button @click="showRole=false">取 消</el-button>
        <el-button type="primary" @click="handleBindRole">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import {thirdCompanyUnion, thirdCompanyList, thirdCompanyAllocateDevice, thirdCompanyRole} from '@/api/work/company'
  import {roleList} from '@/api/account/role'
  import Devices from '@/components/Device'
  import CDialog from '@/components/Dialog'
  import BindDevice from './company_device'
  import {mapGetters} from "vuex";

  export default {
    name: '',
    components: {
      Devices,
      BindDevice,
      CDialog
    },
    computed: {
      ...mapGetters(['employee']),
    },
    mounted: function () {
      this.loadCompany()
      roleList().then(res => {
        this.roles = res.data || []
      })
    },
    data: function () {
      return {
        bindVisible: false,
        deviceVisible: false,
        showRole: false,
        role: {
          uuid: '',
          corpid: '',
        },
        bindBody: {
          code: '',
          corpid: '',
          deviceUuid: [],
        },
        bind: {
          uuid: '',
          name: ''
        },
        roles: [],
        companies: [],
        pagination: {
          name: '',
          start: 0,
          curPage: 1,
          limit: 10,
          total: 0,
        },
      }
    },
    methods: {


      handleCurrentChange(value) {
        this.pagination.curPage = value
        this.pagination.start = (value - 1) * this.pagination.limit
        thirdCompanyList(this.pagination).then(res => {
          this.companies = res.data || []
        })
      },
      loadCompany() {
        let vue = this
        console.log(this.pagination)
        thirdCompanyUnion(this.pagination).then(res => {
          vue.companies = res.data.list || []
          vue.pagination.total = parseInt(res.data.total)
        })
      },
      loadDeviceSearch() {
        let vue = this
        this.pagination.curPage = 1
        this.pagination.start = 0
        thirdCompanyUnion(this.pagination).then(res => {
          vue.companies = res.data.list || []
          vue.pagination.total = parseInt(res.data.total)
        })
      },
      handleBindDepartment() {
        let deviceUuid = this.$refs.devices.getDevices() || []
        if (deviceUuid.length === 0) {
          this.$message.warning("请先选取需要绑定的设备")
          return
        }
        this.bindBody.deviceUuid = deviceUuid
        thirdCompanyAllocateDevice(this.bindBody).then(res => {
          this.bindVisible = false
          this.$message.success('绑定成功')
        })
      },

      handleAllocateRole(company) {
        this.role.corpid = company.corpid
        this.showRole = true
      },
      handleBindRole() {
        if (!this.role.uuid) {
          this.$message.warning("请选取角色")
        } else {
          thirdCompanyRole({
            code: this.employee.code,
            corpid: this.employee.corpid,
            uuid: this.role.uuid,
          }).then(res => {
            this.showRole = false
            this.$message.success('设定成功')
          })
        }

      },

      handleAllocateDevice(company) {
        this.bindVisible = true
        this.bindBody.code = this.employee.code
        this.bindBody.corpid = company.corpid
        this.$nextTick(function () {
          this.$refs.devices.loadDevices(this.bind.name, [], company.corpid)
        })
      },
      handleInputChange(value) {
        this.$refs.devices.loadDevices(value, [], this.bindBody.corpid)
      },

      handleBindDevice(company) {
        this.deviceVisible = true
        this.$nextTick(() => {
          this.$refs.payDevice.loadDevices(company.corpid);
        })
      }

    },
  }
</script>

<style lang="scss">
  .device-index {
    display: flex;

    .device-department {
      width: 180px;
      padding-right: $padding;
      border-right: 1px solid;
      @include set_border_color($--border-color, $--border-color-dark);

      .device-group {
        width: 100%;
        text-align: center;
        @include set_font_color($_theme_color, $--font-color-dark);
        @include set_bg_color(#eef8ff, #454954);
        padding: 4px 0;
        border-radius: $border-radius;
        margin-bottom: $padding;
      }
    }

    .device-list {
      flex: 1;
      padding-left: $padding;
      @media (max-width: 1366px) {
        .el-form-item__label {
          width: 64px;
        }
      }

      @media (max-width: 1442px) {
        .el-form-item__label {
          width: 72px;
        }
      }

      .el-form-item__content {
        width: 150px;

        .clear-right-padding {
          .el-input__inner {
            padding-right: 8px;
          }

        }
      }

      .device-info {
        .device-info-img {
        }

        .device-info-detail {

          p {
            margin-bottom: $padding;
          }

          p:first-child {
            font-size: .8rem;
          }
        }
      }

      .text-right {
        text-align: right;
      }

      .el-table {
        thead {
          tr {
            th {
              border-bottom: 1px solid;
              @include set_border_color($--border-color, $--border-color-dark);
            }
          }
        }

        .cell {
          font-size: $font_little;
        }

      }

      .user-info {
        p:first-child {
          margin-bottom: $padding;
        }
      }

      .el-table--group::after, .el-table--border::after, .el-table::before {
        background-color: transparent;
      }

      .el-table__row:hover {
        @include set_bg_color($background-color-light, $background-color-dark);
      }

      .pagination {
        text-align: right;
        margin-top: $padding-8;

        .el-pagination__total {
          float: left;
        }
      }

      .device-edit, .device-detail {
        cursor: pointer;
        display: inline-block;
        margin-right: $padding-8;
      }

      .device-edit:hover, .device-detail:hover {
        font-weight: 500;
      }

      .device-edit {
        color: $btn_color;
        cursor: pointer;
      }

      .device-detail {
        color: $_theme_color;
        cursor: pointer;
      }

      .circle {
        border-radius: $font_little;
        width: $font_little;
        height: $font_little;
        display: inline-block;
        margin-right: 5px;
      }

      .offline {
        color: #5B8FF9;

        .circle {
          background-color: #5B8FF9;
        }
      }

      .normal {
        color: #5AD8A6;

        .circle {
          background-color: #5AD8A6;
        }
      }

      .warning {
        color: #F6BD16;

        .circle {
          background-color: #F6BD16;
        }
      }

      .fault {
        color: #E8684A;

        .circle {
          background-color: #E8684A;
        }
      }
    }
  }
</style>
