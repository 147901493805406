<template>

  <div>
    <el-table :data="devices">
      <el-table-column label="设备名称" align="center" prop="name"></el-table-column>
      <el-table-column label="设备编号" align="center" prop="uuid"></el-table-column>
      <el-table-column label="产品名称" align="center" prop="productName"></el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <span @click="handleUnBind(scope.row, scope.$index)" class="device_delete">解绑</span>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      @current-change="handleCurrentChange"
      class="pagination"
      :current-page="pagination.curPage"
      :page-size="pagination.limit"
      layout="total, prev, pager, next, jumper"
      :total="pagination.total">
    </el-pagination>
  </div>

</template>

<script>
  import {thirdCompanyDeviceUnion, thirdCompanyDeviceList, thirdCompanyRemoveDevice} from '@/api/work/company'
  import {mapGetters} from "vuex";

  export default {
    name: '',
    components: {},
    mounted: function () {
    },
    computed: {
      ...mapGetters(['employee']),
    },
    data: function () {
      return {
        devices: [],
        pagination: {
          corpid: '',
          start: 0,
          curPage: 1,
          limit: 10,
          total: 0,
        },
      }
    },
    methods: {
      handleCurrentChange(value) {
        this.pagination.curPage = value
        this.pagination.start = (value - 1) * this.pagination.limit
        thirdCompanyDeviceList(this.pagination).then(res => {
          this.devices = res.data || []
        })
      },
      handleUnBind(val, idx) {

        this.$confirm('是否解绑设备', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let vue = this
          thirdCompanyRemoveDevice({corpid: this.pagination.corpid, code: this.employee.code, deviceUuid: val.uuid}).then(res => {
            vue.devices.splice(idx, 1)
          })


        }).catch(() => {

        })

      },

      loadDevices(uuid) {
        let vue = this
        this.pagination.corpid = uuid
        thirdCompanyDeviceUnion(this.pagination).then(res => {
          vue.devices = res.data.list || []
          vue.pagination.total = parseInt(res.data.total)
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .device_delete{
    cursor: pointer;
    color: #f06431;
  }
</style>
